import * as React from "react"
import { Link } from "gatsby"
import Page from "../containers/page"

const ThankYouPage = () => {
  return (
    <Page pageTitle="Thank You" description="">
      <main >
        <div className="text-center mt-36 max-w-3xl m-auto">
          <h1 >Thank You</h1>
          <p>Thank you for taking the time to support Judge Dawn Moody's campaign. Campaigns take a lot of time, effort and resources and she appreciates your support and belief in her.</p>
          <Link to="/" className="btn">Back To Home Page &rarr;</Link>
        </div>

      </main>
    </Page >
  )
}

export default ThankYouPage
